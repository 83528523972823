import { Image, Appear, Notes } from 'mdx-deck';
import { CodeSurfer, CodeSurferColumns, Step } from 'code-surfer';
import { github, vsDark } from "@code-surfer/themes";
import React from 'react';
export default {
  Image: Image,
  Appear: Appear,
  Notes: Notes,
  CodeSurfer: CodeSurfer,
  CodeSurferColumns: CodeSurferColumns,
  Step: Step,
  github: github,
  vsDark: vsDark,
  React: React
};